@import "app/assets/css/variables.scss";

.plugin-card {
  display: flex;
  flex-direction: column;
  gap: $spacing-03;
  padding: $spacing-05;
  border-radius: $spacing-05;
  border: 1px solid #E8E8E8;
  background: #FCFCFC;
  box-shadow: 0px 3px 7px 1px rgba(23, 22, 22, 0.1);
  width: calc(33.33% - 14px);
  min-width: 250px;
  margin-bottom: 0;
  max-width: 350px;

  .plugin-logo {
    flex: 0 0 auto;
    width: auto;
    height: 80px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img,
    svg {
      max-width: 216px;
      height: 100%;
      object-fit: contain;
      border-radius: 4px;
    }
  }

  .header-name {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    padding-right: $spacing-04;
  }

  .plugin-actions {
    display: flex;
    flex-direction: row;
    gap: $spacing-02;
    justify-content: flex-end;
    margin-top: auto;
  }

  .plugin-card-content {
    display: flex;
    flex-direction: row;
    gap: $spacing-05;
    justify-content: flex-start;
    align-items: center;
  }

  .connect-button {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 1200px) {
  .plugin-card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .plugin-card {
    width: 100%;
  }
}
